*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100%;
  min-width: 100%;
}

@media (min-width: 320px) {
  .slider-container {
    width: 116vw;
    width: 93vw;
  }
}

@media (min-width: 640px) {
  .slider-container {
    width: 1140px;
    margin-left: inherit;
  }
}

@media (min-width: 1536px) {
  .slider-container {
    width: 1680px;
    margin-left: inherit;
  }
}

.cards-container {
  display: flex;
  overflow-x: auto;
  position: relative;
  scroll-behavior: smooth;
  overflow-y: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.cards-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.cards-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* career page slider css for csr section */

.csr-card-image {
  width: 19rem !important;
}

@media (min-width: 900px) {
  .csr-card-image {
    max-width: 100%;
    margin: auto;
    align-items: stretch;
  }
}
