.section {
  scroll-snap-align: start;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.9s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.9s ease-in-out;
}

.App {
  overflow-y: scroll;
  height: 100vh;
  scroll-snap-type: y mandatory;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: 'customFont';
  src: url('../public/avenir_lt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


.transition-columns {
  transition: flex 0.7s ease-in;
}
.transition-xyz{
  transition-property: transform; /* Specify the property to transition */
  transition-duration: 0.5s; /* Duration of the transition */
  transition-timing-function: ease-in-out; /* Timing function for the transition */
}
#aboutus .swiper-pagination-bullet-active {
  width: 16px; /* Adjust the width as needed */
  border-radius:10px;
  background-color: #002475;
}
#whoweare .swiper-pagination-bullet-active {
  width: 16px; /* Adjust the width as needed */
  border-radius:10px;
  background-color: #002475;
}
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.animated-text {
  opacity: 0;
  transform: translateY(300px);
  transition: opacity 1.5s ease, transform 1.5s ease;
}

.group:hover .animated-text {
  opacity: 1;
  transform: translateY(0);
}
.bg-gradientob {
  background-image: linear-gradient(to bottom, #0D0D0D, black);
}

.bg-gradientnormal {
  background-image: linear-gradient(to bottom, transparent 55%, black);
}

.bouncing-button {
  cursor: pointer;
}



@keyframes bounce-and-drop {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px); /* Adjust this value to control the downward bounce */
  }
  100% {
    transform: translateY(0);
  }
}



.bounce-and-drop {
  animation: bounce-and-drop 0.6s ease;
}