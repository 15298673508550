
.sliderContainer {
    height: 100vh; /* Set height as needed */

    /* overflow: scroll;
    overscroll-behavior-y: contain;
    scroll-snap-type: y mandatory; */
  }
  /* .sliderContainer::-webkit-scrollbar {
    display: none;
  }
  .slider{
    scroll-snap-align: center;
  }
  img{
    width: 100vw;
  } */

  .parallax-bg {
    /* position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%; */
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
  }
  .sliderContainer .swiper-pagination{
    top: 70%;
    left: 40px;
  }
  .sliderContainer .swiper-pagination-bullet {
    background-color:white;
}
.sliderContainer .swiper-pagination-bullet-active {
  height: 16px; /* Adjust the width as needed */
  border-radius:10px;
  width: 7px
}

.sectionSlider {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.sectionSlider.visible {
  opacity: 1;
}

.sectionSlider .text-content {
  transform: translateY(100px);
  opacity: 0;
  transition: opacity 2.5s ease-in-out, transform 1s ease-in-out;
}

.sectionSlider .text-heading {
  transform: translateY(100px);
  opacity: 0;
  transition: opacity 2.5s ease-in-out, transform 1s ease-in-out;
}

.sectionSlider.visible .text-content {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 0.7s; /* Delay for the text */
}
.sectionSlider.visible .text-heading {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 0s; /* Delay for the text */
}