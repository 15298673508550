* {
    margin: 0;
    box-sizing: border-box;
}

.landingpage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;
    transition: 0.5s;
    /* font-family: "Merianda"; */
    background: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
}

.video-bg {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    z-index: -1;
    object-fit: fit;
    /* background: linear-gradient(96deg, rgba(0, 21, 129, 0.60) 14.91%, rgba(0, 21, 129, 0.60) 50.15%, rgba(0, 32, 199, 0.00) 85.85%); */
}

.txt {
    color: var(--Neutral-21, #FFF);
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    text-align: left;
    /* 76.8px */
}

.bgoverlay {

    display: flex;
    flex-direction: column;
    z-index: 0;
    align-items: flex-start;
    position: absolute;
    left: 10%;
    justify-content: center;
    
}

.buton1 {
    margin-top: 40px;
    background-color: var(--Neutral-21, #FFF);
    color: var(--Primary-P2, #002475);
}



.buton {
    display: flex;
    width: 162px;
    height: 48px;
    padding: 0px 36px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    /* font-family: Avenir; */
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
    /* 125% */
}

.txt3 {
    color: var(--Primary-P2, #002475);
}

.txt2 {
    color: var(--Neutral-10, #808080);
}

.txt4 {
    color: var(--Font-Grey, #5D5C5C);
}

.footerColor {
    background-color: var(--Primary-P2, #3C888E);

}

.footer {
    border: 2px solid #9090A7;
    color: var(--Paragraph, #9090A7);
}

.foot {
    color: var(--Paragraph, #9090A7);
}