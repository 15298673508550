.bar {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 3px 0;
}

* {
    margin: 0;
    box-sizing: border-box;
}

.buton1 {
    margin-top: 40px;
    background-color: var(--Neutral-21, #FFF);
    color: var(--Primary-P2, #002475);
}

.buton2 {
    background-image: linear-gradient(to left, rgba(0, 36, 117, 1), rgba(0, 255, 175, 1));
}
